const reporting = {
  reporting: {
    graph: {
      title: "Talent Map",
      filters: {
        search: {
          label: "Search",
          placeholder: "Search person",
        },
        teams: {
          label: "Teams",
        },
      },
      axes: {
        score: "Average feedback",
        percentage: "Feedback variation (%)",
      },
      teams: {
        noTeam: "No Team",
      },
      detail: {
        title: "Feedback Details",
      },
      tooltip: {
        title: "Talent Measurement",
      },
    },
    export: {
      shortcuts: {
        today: "Today",
        yesterday: "Yesterday",
        past: "The last {{period}} days",
        currentMonth: "This month",
        pastMonth: "Last month",
      },
      modal: {
        types: {
          performanceFollowup: "performance follow-up",
          userFeedback: "feedbacks",
          questionDetail: "question details",
        },
        title: "Export of {{type}}",
        labels: {
          date: "Select a date range",
          teams: "Include teams",
          properties: "Include properties",
          email: "Enter an email",
        },
        placeholders: {
          email: "example@email.com",
        },
        alert: {
          success: "Your report is on its way! This could take a few minutes.",
        },
      },
    },
  },
};

export default reporting;
