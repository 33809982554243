const questions = {
  questions: {
    amountOfAnswers: "Cantidad total de respuestas: {{answers}}",
    score: "Puntaje promedio: {{score}}",
    empty: "No hay información disponible. Intenta cambiando los filtros",
    questionBar: {
      strongNegative: "Fuertemente negativo",
      negative: "Negativo",
      neutral: "Neutral",
      positive: "Positivo",
      strongPositive: "Fuertemente positivo",
    },
    button: {
      title: "Exportar",
    },
    participationRate: {
      title: "Preguntas",
      metric: "Métrica",
      subMetric: "Sub-métrica",
      filter: "Equipo / segmento",
      period: "Periodo",
      cycle: "Ciclo",
      question: "Pregunta",
      results: "Resultados",
      participationNumber: "N° respuestas",
      participationRate: "%",
      score: "Promedio",
      noAvailable: "Datos no disponibles",
      noAnswers: "No hay respuestas para esta pregunta",
      notEnoughAnswers: "Necesitas al menos 3 respuestas para ver resultados",
      noDate: "Sin info de fecha",
      date: "Fecha",
      tooltip:
        "Solo aparecerán en el filtro de equipos, equipos con 5 o más usuarios activos",
    },
    modalQuestions: {
      title: "Exportación de resultados",
      subTitleCycle: "Selecciona un ciclo de medición",
      subTitleTeams: "Incluir resultados de equipos",
      subTitleResults: "Incluir resultados generales",
      subTitleEmail: "Ingresa un correo electrónico",
      tooltipCycle:
        "Seleccione un ciclo de medición para exportar sus resultados.",
      tooltipTeams:
        "Los equipos seleccionados deben contar con al menos 4 participantes durante el ciclo para exportar sus resultados.",
      tooltipResults:
        "Selecciona esta opción para incluir en la exportación una sección con los resultados generales de tu organización.",
      tooltipEmail:
        "Se enviará un documento CSV con todos los resultados generales y de los equipos seleccionados. Solo puedes ingresar un correo electrónico.",
      alertSuccess:
        "¡Se ha enviado con éxito! Esto podría tardar unos minutos.",
      sending: "Enviando...",
      error: {
        NO_EMAIL: "Se requiere dirección de correo electrónico.",
        MISSING_PARAMETERS:
          "Faltan parámetros requeridos. Por favor, contacte a soporte.",
        NO_RESPONSES: "No se encontraron respuestas para esta cuenta.",
        EMPTY_TEAM: "Uno de los equipos seleccionados está vacío.",
        NOT_ENOUGH_USERS:
          "No hay suficientes usuarios activos para generar un reporte.",
        NO_RESPONSES_TEAM:
          "Uno de los equipos seleccionados no tiene respuestas.",
        NO_ACTIVE_RESPONSES: "No hay respuestas de usuarios activos.",
        NOT_ENOUGH_ACTIVE_USERS:
          "Uno de los equipos seleccionados no tiene suficientes usuarios activos.",
        INTERNAL_ERROR:
          "Ocurrió un error inesperado, por favor intente nuevamente o contacte a soporte.",
      },
    },
  },
};

export default questions;
