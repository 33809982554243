import React, { Suspense, lazy, useEffect } from "react";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import GlobalStyles from "./theme/globalStyles";
import theme from "./theme";
import UserPrivateRoute from "./components/User/UserPrivateRoute";

import CustomNavigationClient from "./utils/NavigationClient";

import LoadingPage from "./components/Fallback/LoadingPage";
import InfoModal from "./utils/InfoModal";
import Loader from "./utils/Loader";

import { clientAxios as axios } from "./apiClients";

const NotFoundView = lazy(() => import("./components/NotFoundView"));
const AdminOnboarding = lazy(() => import("./components/Admin/Onboarding"));
const Onboarding = lazy(() => import("./components/User/Onboarding"));

const AdminDashboardLayout = lazy(() =>
  import("./components/AdminDashboardLayout")
);

const OnboardingTeamsConnect = lazy(() =>
  import("./components/Admin/Teams/OnboardingTeamsConnect")
);

const OnboardingTeamsAdminConsent = lazy(() =>
  import("./components/Admin/Teams/OnboardingTeamsAdminConsent")
);
const SurveyAnswer = lazy(() => import("./components/SurveyAnswer"));
const Feedback = lazy(() => import("./components/Feedback/Feedback"));
const BozLogin = lazy(() => import("./components/Login/BozLogin"));

const BozPasswordChanged = lazy(() =>
  import("./components/ResetPassword/BozPasswordChanged")
);

const BozChangePassword = lazy(() =>
  import("./components/ResetPassword/BozChangePassword")
);

const BozPasswordReseted = lazy(() =>
  import("./components/ResetPassword/BozPasswordReseted")
);

const BozResetPassword = lazy(() =>
  import("./components/ResetPassword/BozResetPassword")
);
const EmailSurvey = lazy(() => import("./components/EmailSurvey/EmailSurvey"));

const UserParticipation = lazy(() =>
  import("./components/UserParticipation/UserParticipation")
);

const RecognitionsReport = lazy(() =>
  import("./components/Recognitions/RecognitionsReport")
);
const RecognitionsRequest = lazy(() =>
  import("./components/Recognitions/RecognitionsRequest")
);
const TimeOff = lazy(() =>
  import("./components/Admin/Settings/General/TimeOff")
);

const token = localStorage.getItem("jwtToken");
if (token) {
  axios.defaults.headers.common.Authorization = token;
} else {
  delete axios.defaults.headers.common.Authorization;
}

const App = function ({ pca }) {
  const historyHook = useHistory();
  const info = useSelector((state) => state.info);
  const loader = useSelector((state) => state.loader);

  const dispatch = useDispatch();
  const navigationClient = new CustomNavigationClient(historyHook);
  pca.setNavigationClient(navigationClient);

  useEffect(() => {
    let unSubscribe = false;
    if (!unSubscribe) {
      if (loader.open) {
        setInterval(() => {
          dispatch({ type: "CLOSE_LOADER" });
        }, 10000);
      }
    }
    return () => {
      unSubscribe = true;
    };
  }, [info, loader]);

  return (
    <MsalProvider instance={pca}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Router>
            <Suspense fallback={<LoadingPage />}>
              <Switch>
                <Route exact path="/" component={BozLogin} />
                <Route
                  exact
                  path="/reset-password"
                  component={BozResetPassword}
                />
                <Route
                  exact
                  path="/password-reseted"
                  component={BozPasswordReseted}
                />
                <Route
                  exact
                  path="/password-changed"
                  component={BozPasswordChanged}
                />
                <Route
                  exact
                  path="/change-password"
                  component={BozChangePassword}
                />
                <Route
                  exact
                  path="/survey/:survey_session"
                  component={SurveyAnswer}
                />
                <UserPrivateRoute
                  path="/admin"
                  component={AdminDashboardLayout}
                />
                <Route
                  exact
                  path="/onboarding/:invitation"
                  component={Onboarding}
                />
                <Route
                  exact
                  path="/daily-survey/:token"
                  component={EmailSurvey}
                />
                <Route
                  exact
                  path="/account_activation/:id"
                  component={AdminOnboarding}
                />
                <Route
                  exact
                  path="/onboarding-connect-to-teams"
                  component={OnboardingTeamsConnect}
                />
                <Route
                  exact
                  path="/admin-consent-teams"
                  component={OnboardingTeamsAdminConsent}
                />
                <Route
                  exact
                  path="/user-participation/:token"
                  component={UserParticipation}
                />
                <Route exact path="/feedback" component={Feedback} />
                <Route
                  exact
                  path="/recognitions-report/:id"
                  component={RecognitionsReport}
                />
                <Route
                  exact
                  path="/recognitions-request"
                  component={RecognitionsRequest}
                />
                <Route exact path="/linkedin" component={LinkedInCallback} />
                <Route
                  exact
                  path="/admin/settings/timeOff"
                  component={TimeOff}
                />
                <Route component={NotFoundView} />
              </Switch>
              {loader && loader.open && <Loader />}
              {info && info.open && (
                <InfoModal open={info.open} type={info.type} text={info.text} />
              )}
            </Suspense>
          </Router>
        </ThemeProvider>
      </LocalizationProvider>
    </MsalProvider>
  );
};

App.propTypes = {
  pca: PropTypes.instanceOf(PublicClientApplication).isRequired,
};

export default App;
