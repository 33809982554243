const configuration = {
  configuration: {
    generalData: "Dados gerais",
    organizationName: "Nome da organização",
    industryType: "Tipo de indústria",
    language: "Idioma",
    languages: {
      en: "Inglês",
      es: "Espanhol",
      pr: "Português",
      pt: "Português",
    },
    newValue: "Novo valor",
    newValueLengs: {
      es: "Nuevo valor",
      en: "New value",
      pt: "Novo valor",
    },
    segmentRequired: "Complete pelo menos um campo de idioma",
    country: "País",
    title: {
      general: "Geral",
      dataAnalysis: "Análise de dados",
      preferences: "Preferências de envio",
    },
    timezone: "Fuso horário",
    integrations: {
      title: "Integrações",
      description:
        "Você pode personalizar as configurações das suas integrações.",
    },
    sendPreferences: {
      title: "Canais de comunicação",
      description:
        "Você pode alterar as preferências e os canais de comunicação.",
      slack: "Permitir o envio de interações através do Slack",
      slackTooltip:
        "Você precisa estar conectado ao Slack para ativar essa opção",
      mail: "Permitir o envio de interações através do E-mail",
      msTeams: "Permitir o envio de interações através do Teams",
    },
    onboarding: {
      title: "Onboarding",
      description: "Você pode escolher o tipo de onboarding",
      onboardingSwitch: {
        title: "Ativar onboarding para novos colaboradores",
        input: "Selecione a pesquisa",
      },
      infoDaysSwitch: {
        title: "Atrasar a participação das micro pesquisas",
        input: "Selecione os dias",
      },
      selectInfoSwitch: {
        title: "Solicitar informações pessoais no início do onboarding",
        input: "Selecione as propriedades",
        teamsInfo: "Solicitar informações sobre a equipe",
        tooltip:
          "Para solicitar informações pessoais no início do onboarding, você precisa ter o onboarding ativado",
      },
      tabs: {
        all: "Todos",
        bozTemplates: "Modelos Boz",
        templates: "Modelos",
      },
      selectAll: "Selecionar tudo",
      standards: "Padrões",
      personalizated: "Personalizadas",
      other: "Outros",
      modal: {
        title: "Confirmar nova configuração",
        content:
          "Tem certeza de que deseja salvar esta nova configuração para futuros colaboradores?",
      },
    },
    recognitionsPreferences: {
      title: "Reconhecimentos",
      description: "Configure a frequência do relatório de reconhecimentos",
      businessRecognitions: "Reconhecimentos corporativos",
      teamRecognirions: "Reconhecimentos de equipe",
      automaticSendRecognitions: "Envio automático de reconhecimentos",
      active: "Ativo",
      inactive: "Inativo",
      addValue: "Adicionar valor",
      settingChannel: "Ativar Reconhecimentos Públicos",
      realTime: "Tempo real",
      settingSchedule: "Definir horário de envio de reconhecimentos",
      hour: "Hora",
      saveMessage: "Preferências de envio de reconhecimentos salvas",
      sendRecognitionRequestCta: "Enviar solicitação de reconhecimentos",
      sendRecognitionReportCta: "Enviar relatório de reconhecimentos",
      recognizableUsers:
        "Serão enviadas solicitações de reconhecimento para {{total}} colaboradores",
      reportableUsers:
        "Será enviada uma mensagem para os colaboradores reconhecidos",
      recognitionRequestSent:
        "Solicitação de reconhecimentos enviada com sucesso",
      recognitionReportSent: "Relatório de reconhecimentos enviado com sucesso",
      repeatedRecognitionValues:
        "Existem valores de reconhecimento ou ícones duplicados",
      repeatedRecognitionValuesBackCta: "Voltar",
      recogntionValueLength: "{{length}} de {{total}} caracteres no máximo",
      recognitionValuesSave:
        "Tem certeza de que deseja salvar as configurações?",
      recognitionValuesSaveWarning:
        "Tem certeza de que deseja modificar as categorias?",
      recognitionValuesSaveSubtitle:
        "Ao fazer isso, você deixará de ver as categorias que foram excluídas e novas categorias serão adicionadas. Você verá as últimas categorias atribuídas.",
      recognitionValuesSaveCta: "Salvar",
      recognitionValuesCancelCta: "Cancelar",
      tooltipDisableButton:
        "Para usar a solicitação manual de reconhecimentos, primeiro desative o envio automático de reconhecimentos",
      notSendRequestModal: {
        title: "O envio automático de reconhecimentos está ativado",
        subtitlePartOne:
          "Para enviar um reconhecimento manual, desative o envio automático de reconhecimentos nas configurações ou ",
        subtitlePartTwo: " e tente novamente",
        clickHere: "clique aqui",
      },
      sendRequestModal: {
        title:
          "A solicitação de reconhecimentos será enviada agora para {{numberOfUsers}} colaboradores",
        subtitle:
          "Selecione a data e a hora de envio do relatório de reconhecimentos",
      },
      someRecognitionWhenSent:
        "Já foi enviada uma solicitação de reconhecimentos neste mês",
      loadCustomEmoji: "Carregar emojis de valores personalizados",
    },
    emojiLoader: {
      title: "Importe seus emojis",
      explanation:
        "É melhor usar imagens quadradas com menos de 128 KB, que tenham fundo transparente nos formatos png, jpg ou jpeg. Se sua imagem for muito grande, tentaremos redimensioná-la.",
      errorType: "Tipo de imagem incorreto!",
      saveSuccess: "Emoji salvo com sucesso!",
      saveError:
        "Não foi possível salvar o emoji, tente novamente ou entre em contato com o suporte.",
    },
    maxRecognition: {
      monthlyRecognitions: "Quantidade de reconhecimentos mensais",
      maximumPerColaborator: "Máximo por colaborador",
      nRecognitions: "{{ nRecognitions }} reconhecimentos",
      unlimited: "Sem limite",
    },
    surveys: {
      surveyTitle: "Agende suas pesquisas",
      frequency: "Frequência de envio de pesquisas",
      preferSchedule: "Horário preferido de envio",
      preferenceDays: "Dias de envio",
      questionQuantity: "Quantidade de perguntas",
    },
    slack: {
      connect: "Conectar ao Slack",
      alreadyConnected: "Você já está conectado ao Slack",
      automaticTeamJoin: "Adicionar automaticamente novos usuários",
      automaticInfoUpdate: "Atualizar informações dos usuários automaticamente",
      tooltips: {
        automaticTeamJoin:
          "Quando ativado, sempre que uma pessoa nova entrar no seu espaço de trabalho no Slack, ela será adicionada automaticamente ao Burnout-Zero",
        automaticInfoUpdate:
          "Quando ativado, as informações dos usuários serão mantidas sincronizadas sempre que forem alteradas no Slack (foto, nome, etc)",
      },
    },
    breadcrumbs: {
      settings: "Configurações",
      properties: "Propriedades",
      organizationDetails: "Detalhes da organização",
      integrations: "Integrações",
      sendPreferences: "Canais de comunicação",
      recognitionsPreferences: "Reconhecimentos",
      segments: "Segmentos",
      newSegment: "Novo segmento",
      birthdayBot: "BirthdayBoz",
      timeOff: "Tempos livres",
      newTimeOff: "Adicionar Colaboradores",
      onboarding: "Onboarding",
    },
    teams: {
      connect: "Conectar ao MS Teams",
      alreadyConnected: "Você já está conectado ao Microsoft Teams",
      upgradeIntegration: "Atualizar integração",
      upgradingIntegration: "Atualizando integração",
      updatePermissions: "Atualizar permissões",
      upgradeSuccesful: "Atualização bem-sucedida",
      setPermissions: "Autorizar novas permissões",
      backToHome: "Voltar para a página inicial",
    },
    sections: {
      general: {
        name: "Geral",
        subsections: {
          details: {
            name: "Detalhes da organização",
            description:
              "Atualize as informações da sua organização e o idioma.",
          },
          integrations: {
            name: "Integrações",
            description: "Verifique o status das suas integrações.",
          },
        },
      },
      preferences: {
        name: "Preferências de envio",
        subsections: {
          sendPreferences: {
            name: "Canal de comunicação",
            description:
              "Configure o canal de envio para as suas comunicações.",
          },
          recognitions: {
            name: "Reconhecimentos",
            description:
              "Configure os seus valores organizacionais para o reconhecimento.",
          },
          calendar: {
            name: "Calendário",
            description:
              "Configure a frequência e as configurações dos seus envios.",
          },
          birthdayBot: {
            name: "BirthdayBoz",
            description: "Nunca mais perca um aniversário!",
          },
          onboarding: {
            name: "Onboarding",
            description: "Configure o onboarding para novos colaboradores.",
          },
        },
      },
      birthdayBot: {
        name: "BirthdayBoz",
        description: "Nunca mais perca um aniversário!",
      },
      data: {
        name: "Análise de dados",
        subsections: {
          properties: {
            name: "Propriedades",
            description:
              "Crie e edite propriedades para segmentar os seus colaboradores.",
          },
          filters: {
            name: "Segmentos",
            description:
              "Segmente os seus colaboradores para análise com base nos seus critérios.",
          },
        },
      },
    },
    properties: {
      title: "Propriedades",
      description:
        "Você precisa criar uma propriedade primeiro para segmentar os membros da sua organização.",
      searchBar: {
        placeholder: "Pesquise uma propriedade",
      },
      table: {
        headers: {
          name: "Nome",
          type: "Tipo",
          createdBy: "Criado por",
          category: "Categoria",
        },
        badge: {
          custom: "Personalizada",
          standard: "Padrão",
        },
        types: {
          Date: "Data",
          Options: "Opções",
          Numeric: "Numérico",
          Text: "Texto",
        },
        buttons: {
          delete: "Excluir propriedade",
          edit: "Editar propriedade",
        },
      },
      createProperty: {
        title: "Nova propriedade",
        description: "Isso permitirá segmentar os membros da sua organização",
        name: "Nome da propriedade",
        type: "Tipo de propriedade",
        values: "Valores da propriedade",
        placeholder: "Por exemplo: Desenvolvimento",
        addValue: "Adicionar novo valor",
        create: "Criar propriedade",
        nameError: "Insira um nome para a propriedade",
      },
      assignProperties: {
        button: "Atribuir",
        body: {
          title: "Atribuir propriedades aos usuários",
          tipsTitle:
            "Antes de fazer o upload da planilha, certifique-se de que:",
          tipOne: "As propriedades do tipo data estejam no formato AAAA/MM/DD.",
          tipTwo:
            "As propriedades do tipo numérico sejam números inteiros sem decimais.",
          tipThree:
            "Para as propriedades do tipo opção, verifique se o valor fornecido realmente existe na propriedade (ex: Masculino).",
          downloadExample: "Baixar modelo de planilha",
          upload: "Enviar propriedades",
          loading: "Isso pode levar alguns segundos",
        },
      },
      propertiesValidationError: {
        title: "Ocorreu um erro",
        caption: "Verifique o seguinte",
        invalid: "Propriedades inexistentes",
        valid: "Propriedades corretas",
        tip: "Verifique se as propriedades inexistentes estão criadas na conta ou se estão digitadas corretamente na planilha.",
        retry: "Tentar novamente",
      },
      editProperty: {
        title: "Editar propriedade",
        description: "Você só pode alterar o nome das propriedades existentes.",
        name: "Nome da propriedade",
        type: "Tipo de propriedade",
        values: "Valores da propriedade",
        typeForbidden: "Não é permitido alterar o tipo da propriedade",
        update: "Atualizar propriedade",
        addValue: "Adicionar novo valor",
      },
      deleteProperty: {
        title: "Excluir esta propriedade?",
        description:
          "Se você excluir esta propriedade, todos os segmentos associados a ela também serão excluídos.",
        accept: "Aceitar",
        cancel: "Cancelar",
      },
      importResult: {
        title: "Resultado da importação",
        email: "E-mail",
        rowNumber: "Nº da linha",
        retry: "Tentar novamente",
        resume:
          "Verifique o resultado da sua importação para poder corrigir os erros:",
        NUMERIC_VALUE_INVALID: "O valor numérico inserido é inválido",
        INVALID_OPTION_VALUE:
          "A opção inserida como valor não existe na propriedade",
        DATE_FORMAT_INVALID: "O formato de data inserido é inválido",
        USER_DOESNT_EXIST: "O usuário inserido não existe na conta",
        EMAIL_EMPTY: "O campo de e-mail está vazio",
        VALUE_EMPTY: "O campo está vazio",
      },
    },
    organizationDetails: {
      title: "Detalhes da organização",
      description: "Você pode editar as informações gerais da sua organização.",
    },
    segments: {
      title: "Segmentos",
      description:
        "Monitore diferentes grupos de membros com base em características comuns.",
      button: "Criar",
      table: {
        headers: {
          name: "Segmento",
          properties: "Propriedades",
          members: "Membros",
        },
        tooltip:
          "Este segmento não estará disponível nos filtros por ter menos de 5 membros ativos.",
      },
      deleteModal: {
        title: "Excluir segmento",
        description: "Tem certeza de que deseja excluir este segmento?",
        accept: "Aceitar",
        cancel: "Cancelar",
      },
      conditions: {
        IS_BEFORE: "é antes de",
        IS_EXACTLY: "é exatamente",
        IS_AFTER: "é depois de",
        IS_EQUAL_TO: "é igual a",
        IS_NOT_EQUAL_TO: "não é igual a",
        IS_LESSER_THAN: "é menor que",
        IS_GREATER_THAN: "é maior que",
      },
      viewSegment: {
        property: "Propriedade",
        condition: "Condição",
        value: "Valor",
      },
    },
    newSegment: {
      title: "Criar novo segmento",
      description:
        "Use segmentos para agrupar seus colaboradores com base em suas propriedades.",
      emptyState: {
        title: "Crie segmentos para identificar tendências de grupo",
        description:
          "Você pode monitorar diferentes grupos de colaboradores com base em suas características comuns.",
        button: "Quero saber mais",
      },
      createForm: {
        name: {
          label: "Nome do segmento",
          placeholder:
            "Escolha um nome para o segmento, por exemplo: Homens menores de 30 anos",
        },
        rules: {
          title: "Regras e propriedades",
          select: {
            all: "Todas",
            any: "Qualquer",
            hasTo: "Tem que cumprir",
            following1: "as seguintes",
            following2: "das seguintes",
          },
        },
        propertyRules: {
          property: "Propriedade",
          condition: "Condição",
          value: "Valor",
          add: "Adicionar nova regra",
          button: "Criar segmento",
          nameHelperText: "Insira um nome para o segmento",
          alerts: {
            noUsers:
              "Não há usuários associados a este segmento. Tente modificar ou adicionar regras.",
            oneUser:
              "Há apenas {{active}} usuário ativo associado a este segmento e {{inactive}} usuários inativos",
            lessThanFiveUsers:
              "Há apenas {{active}} usuários ativos associados a este segmento e {{inactive}} usuários inativos.",
            ok: "OK! Há {{active}} usuários ativos e {{inactive}} usuários inativos associados a este segmento.",
          },
        },
      },
    },
    birthdayBot: {
      title: "BirthdayBoz",
      description: "Configure o seu bot de aniversário.",
      activate: "Ative seu bot de aniversário",
      birthdays: "Enviar aniversários",
      anniversaries: "Enviar aniversários de trabalho",
      textToLoad: "Carregue seus aniversários e datas comemorativas com Excel",
      selectGroup: "Escolha seu grupo de envio",
      selectChannel: "Escolha seu canal de envio",
      selectSchedule: "Escolha seu horário",
      disclaimer: "*A mensagem será enviada com base no fuso horário da conta",
      informativeParagraph:
        "¡Você pode definir o fuso horário da sua conta para acessar as comemorações do Aniversário BOZ. Por favor clique ",
      here: "aqui!",
      upload: {
        title: "Atribuir aniversários e datas comemorativas aos usuários",
        subTitle: "Informações importantes sobre BirthdayBOZ :",
        paragraph1:
          "As datas devem ser inseridas no formato AAAA/MM/DD em tipo de texto.",
        paragraph2:
          "Os usuários também poderão fazer upload de suas datas pelo canal de comunicação, substituindo as datas importadas pela planilha.",
        paragraph3:
          "Cada dia em que ocorrer um aniversário ou aniversário, BirthdayBOZ enviará uma mensagem automática de comemoração para o canal selecionado.",
        file: "Carregar arquivo",
        results: "Resultados da importação",
        tryAgain: "Importar novamente",
        button: "Carregar datas com Excel",
        errors: {
          INVALID_DATE: "Data inválida",
          USER_DOESNT_EXIST: "Usuário não existe na conta",
        },
        headers: {
          row: "Número da linha",
          email: "Email",
          comments: "Comentários",
        },
      },
    },
    newTimeOff: {
      title: "Adicione colaboradores para a política",
      description: "Você sempre pode voltar para modificar os colaboradores",
      titleModal: "Você está criando uma nova política",
    },
    timeOff: {
      title: "Tempos livres",
      description: "Crie suas políticas de tempo livre",
      button: "Criar política",
      name: "Nome da política",
      type: "Tipo de política",
      payType: "Pagamento (Sim/Não)",
      quantityOfCollabs: "Quantidade de colaboradores",
      addCollabs: "Adicionar colaboradores",
      edit: "Editar política",
      delete: "Excluir política",
      setDyas: "Definir dias",
      fixedDays: "Quantidade de dias",
      initialDays: "Dias iniciais",
      additionalDays: "Dias por mês trabalhado",
      prorated: "Prorratear política?",
      notice: "Mínimo de dias para solicitar o tempo livre",
      autoAdd: "Adicionar automaticamente novos colaboradores",
      editTitle: "Edite as características da política",
      filterByName: "Filtrar por nome",
      filterByMail: "Filtrar por e-mail",
      selectFilters: "Selecionar filtros",
      selectAll: "Selecionar tudo",
      segments: "Segmentos",
      teams: "Equipes",
      typeOfPolicy: {
        legal: "Legal",
        administrative: "Administrativa",
        additional: "Adicional",
      },
      typeOfPayment: {
        paid: "Paga",
        noPaid: "Não paga",
      },
      typeOfSetDays: {
        fixed: "Fixos",
        dynamic: "Dinâmicos",
        unlimited: "Ilimitados",
      },
      yesOrNo: {
        yes: "Sim",
        no: "Não",
      },
      view: {
        title: "Detalhe da política",
        caption: "Apenas poderá ver, não poderá editar aqui.",
        name: "Nome",
        mail: "E-mail",
        noTeam: "Sem equipe",
        group: "Equipe",
      },
      deleteSucces: "Política excluída",
      updateSucces: "Política atualizada",
      editPolicy: "Editar política",
      deletePolicy: "Excluir política",
      empty: "NÃO HÁ POLÍTICAS CRIADAS",
      editModal: {
        title: "Edite as características da política",
      },
      deleteModal: {
        title: "Excluir política",
        caption: "Ao confirmar, excluirá a política",
      },
      new: {
        title: "Crie as condições da política",
        create: "Criar política",
        accumulative: "Acumulativa",
        accumulativeTooltip:
          "Quando a política é acumulativa, o tempo livre será acumulado no tempo restante da política.",
        selectDateType: "Selecione o tipo de data",
        selectDate: "Selecione uma data",
        hiringDate: "Data de contratação",
        fixed: "Fixo",
      },
      groups: "Equipes",
      noSegment: "Sem segmento",
      add: {
        title: "Nome da política que está modificando: ",
        filters: {
          byName: "Filtrar por nome",
          byMail: "Filtrar por e-mail",
          byOthers: "Selecionar equipes/segmentos",
        },
        name: "Nome",
        mail: "E-mail",
        group: "Equipe",
        segment: "Segmento",
        filter: "Filtrar",
      },
    },
  },
};

export default configuration;
