const reporting = {
  reporting: {
    graph: {
      title: "Mapa de Talentos",
      filters: {
        search: {
          label: "Pesquisar",
          placeholder: "Pesquisar pessoa",
        },
        teams: {
          label: "Equipes",
        },
      },
      axes: {
        score: "Média de feedback",
        percentage: "Variação de feedback (%)",
      },
      teams: {
        noTeam: "Sem equipe",
      },
      detail: {
        title: "Detalhes do feedback",
      },
      tooltip: {
        title: "Medição de Talento",
      },
    },
    export: {
      shortcuts: {
        today: "Hoje",
        yesterday: "Ontem",
        past: "Os últimos {{period}} dias",
        currentMonth: "Este mês",
        pastMonth: "Mês passado",
      },
      modal: {
        types: {
          performanceFollowup: "acompanhamento de desempenho",
          userFeedback: "feedbacks",
          questionDetail: "detalhes das perguntas",
        },
        title: "Exportação de {{type}}",
        labels: {
          date: "Selecione um intervalo de datas",
          teams: "Incluir equipes",
          properties: "Incluir propriedades",
          email: "Digite um e-mail",
        },
        placeholders: {
          email: "exemplo@email.com",
        },
        alert: {
          success:
            "Seu relatório está a caminho! Isso pode levar alguns minutos.",
        },
      },
    },
  },
};

export default reporting;
