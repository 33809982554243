const reporting = {
  reporting: {
    graph: {
      title: "Mapa de talento",
      filters: {
        search: {
          label: "Buscador",
          placeholder: "Buscar persona",
        },
        teams: {
          label: "Equipos",
        },
      },
      axes: {
        score: "Promedio feedback",
        percentage: "Variación de feedback (%)",
      },
      teams: {
        noTeam: "Sin equipo",
      },
      detail: {
        title: "Detalle feedbacks",
      },
      tooltip: {
        title: "Medición de talento",
      },
    },
    export: {
      shortcuts: {
        today: "Hoy",
        yesterday: "Ayer",
        past: "Los últimos {{period}} días",
        currentMonth: "Este mes",
        pastMonth: "El mes pasado",
      },
      modal: {
        types: {
          performanceFollowup: "seguimiento de performance",
          userFeedback: "feedbacks",
          questionDetail: "detalle de preguntas",
        },
        title: "Exportación de {{type}}",
        labels: {
          date: "Selecione un rango de fechas",
          teams: "Incluir equipos",
          properties: "Incluir propiedades",
          email: "Ingrese un email",
        },
        placeholders: {
          email: "ejemplo@correo.com",
        },
        alert: {
          success: "¡Tu reporte va en camino! Esto podría tomar unos minutos.",
        },
      },
    },
  },
};

export default reporting;
