const questions = {
  questions: {
    amountOfAnswers: "Total amount of answers: {{answers}}",
    score: "Average score: {{score}}",
    empty: "There is no available info. Try changing the filters",
    questionBar: {
      strongNegative: "Strongly negative",
      negative: "Negative",
      neutral: "Neutral",
      positive: "Positive",
      strongPositive: "Strongly positive",
    },
    button: {
      title: "Export",
    },
    participationRate: {
      title: "Questions",
      metric: "Metric",
      subMetric: "Sub-metric",
      filter: "Team / segment",
      period: "Period",
      cycle: "Cycle",
      question: "Question",
      results: "Results",
      participationNumber: "N° of answers",
      participationRate: "%",
      score: "Average",
      noAvailable: "No data available yet",
      noAnswers: "There are no answers for this question",
      noDate: "No date info",
      date: "Send date",
      notEnoughAnswers: "You need at least 3 answers to see results",
      tootilp:
        "Only teams with 5 or more active users will be available in the filter",
    },
    modalQuestions: {
      title: "Exporting results",
      subTitleCycle: "Select a measurement cycle",
      subTitleTeams: "Include team results",
      subTitleResults: "Include general results",
      subTitleEmail: "Enter an email",
      tooltipCycle: "Select a measurement cycle to export your results.",
      tooltipTeams:
        "Selected teams must have at least 4 participants during the cycle to export their results.",
      tooltipResults:
        "Select this option to include a section with the general results of your organization in the export.",
      tooltipEmail:
        "A CSV document with all the overall and selected team results will be sent. You can only enter one email address.",
      alertSuccess:
        "It has been sent successfully! This may take a few minutes.",
      sending: "Sending...",
      error: {
        NO_EMAIL: "Email address is required.",
        MISSING_PARAMETERS:
          "Missing required parameters. Please contact support.",
        NO_RESPONSES: "No responses found for this account.",
        EMPTY_TEAM: "One of the selected teams is empty.",
        NOT_ENOUGH_USERS: "Not enough active users to generate a report.",
        NO_RESPONSES_TEAM: "One of the selected teams has no responses.",
        NO_ACTIVE_RESPONSES: "No responses from active users.",
        NOT_ENOUGH_ACTIVE_USERS:
          "One of the selected teams has not enough active users.",
        INTERNAL_ERROR:
          "Unexpected error occured, please try again or contact support.",
      },
    },
  },
};

export default questions;
